<template>

  <div id="footer" class="container-fluid">
    <div class="logo">

    </div>
    <p class="title">大谷物联科技有限公司</p>
    <p class="address_tel_fax">
      <span>地址：深圳市龙岗区坂田街道雅星路8号星河双子塔-西塔1705B</span>

    </p>
    <p class="email_wx">
      <span>邮箱：tangchenxi@daguiot.com  </span>
      <span>微信号: szdaguiot</span>
    </p>
    <p class="email_wx">
      <span>Tel：189-2344-6082</span>
    </p>
    <div class="logo">
      <p>
        <span>
          <router-link  target= "_blank" style="color: whitesmoke" to="/policy">服务协议</router-link>
        </span>
        &nbsp;
        <span>
          <router-link target= "_blank" style="color: whitesmoke"  to="/privacy">隐私政策</router-link>
        </span>
      </p>
    </div>
    <p>经营许可证编号:B1.B2-20250353</p>
    <p class="copy">备案号:<a  href="https://beian.miit.gov.cn/" style="color: #d3d3d3">粤ICP备2023131534号-1</a></p>
  </div>
</template>
<script>
export default {
  name: "Footer",
  data() {
    return {};
  }
};
</script>
<style scoped>
#footer {
  width: 100%;
  height: 100%;
  color: #fff;
  background: #353970;
  overflow: hidden;
  text-align: center;
}
.logo {
  width: 130px;
  height: 10px;
  margin: 50px auto 20px;
}
.title {
  font-size: 25px;
  margin-bottom: 20px;
}
.address_tel_fax {
  color: #d3d3d3;
  font-size: 14px;
  margin: 10px 0;
}
.email_wx {
  color: #d3d3d3;
  font-size: 14px;
}
.copy {
  color: #d3d3d3;
  font-size: 14px;
  margin: 50px 0 10px;
}
@media screen and (max-width: 997px) {
  .title {
    font-size: 20px;
  }
  .address_tel_fax {
    font-size: 12px;
  }
  .email_wx {

  font-size: 12px;
}
.copy {
  font-size: 12px;
  margin: 30px 0 10px;
}
}
</style>

